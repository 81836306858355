import React from 'react'
import { Container } from "react-bootstrap";
import logo from "./images/heritage_logo.png";
import "./App.scss";

export function Main() {

    return (
        <>
        <section class="hero-1-bg wrapper position-relative bg-light d-flex align-items-center" id="home">

        <Container className="book-bg">
            <div className="row align-items-center">
                <div className="col-lg-6">
                    <div className="hero-title pr-lg-5">
                        <h1 className="hero-1-title mb-4 font-weight-normal line-height-1_4">
                            <span className="text-primary font-weight-medium"><img src={logo} width="50px" alt="logo" /></span> Heritage Polytechnic
                        </h1>
                        
                        <p className="mb-4 pb-2 font-weight-medium">Welcome to Heritage Polytechnic's Library access page. The platform allows many library users to study spaces or books and journals at other libraries which belong to the scheme. Some library users may be able to borrow print books from other libraries too. </p>
                        <img className="book-display img-fluid rounded mx-auto" alt="book" />
                        <p className="mb-4 pb-2 font-weight-medium">However, to use library facilities and services the student must have access to them.</p>
                        <a href="http://elib.heritagepoly.edu.ng/" target="__blank" rel="noopener" className="btn btn-success">Library Access <span className="ml-2 right-icon">&#8594;</span></a>
                    </div>
                </div>

                <div className="col-lg-6 ">
                    <div className="mt-5 mt-lg-0 p-4">

                    </div>
                </div> 
          </div>
        </Container>
        </section>
      </>
    )
}

export default Main;